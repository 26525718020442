import React from "react"
import { Link } from "gatsby"

import Layout from "../../../components/layout"
import SEO from "../../../components/seo"

import hhh from '../../../images/hhhhhhh.png'


const tehnilisedAndmed = () => (
  <Layout>
    <SEO title="Siirdeseinte tehnilised andmed" />
    
    <div className="header-bottom">
      <div className="bg-color-bottom">
        <div className="header-bottom-text">
          <div className="intro-para text-center quote">
              <h1>TEHNILISED ANDMED</h1>
              <hr className="footer-line"></hr>
          </div>
        </div>
      </div>
   </div>
   
   <div className="epoporandad" style={{
          maxWidth: 1300,
          margin: 'auto',
        }}>

        <div className="wrapper-epo">
            <Link to='/ruumijagajad/voldikuksed/'>
            <div className="epo">
                <h2>Voldikuksed</h2>
            </div>
            </Link>
            <Link to='/ruumijagajad/lootsuksed/'>
            <div className="epo">
                <h2>Lõõtsuksed</h2>
            </div>
            </Link>
            <Link to='/ruumijagajad/siirdeseinad/'
            activeStyle={{ borderBottom: '3px solid #ffa609' }}
            partiallyActive={true}>
            <div className="epo">
                <h2>Siirdeseinad</h2>
            </div>
            </Link>
            <Link to='/ruumijagajad/voldikseinad/'> 
            <div className="epo">
                <h2>Voldikseinad</h2>
            </div>
            </Link>
            <Link to='/ruumijagajad/referentsid/'>
            <div className="epo">
                <h2>Referentsid</h2>
            </div>
            </Link>
         </div>

      <div className="ta-wrapper">
          <h3>Mõõdud ja kaalud</h3>
        <div className="ta-text">
            <b>Paksus:</b>
            <p>85-110 mm, sõltuvalt helipidavusest ja tuleklassist</p>

            <b>Kaal/m²:</b>
            <p>26-63 kg sõltuvalt helipidavusest ja tuleklassist</p>

            <b>Elemendi laius:</b>
            <p>minimaalne 400 mm ja maksimaalne 1250 mm</p>

            <b>Elemendi kõrgus:</b>
            <p>maksimaalselt 6000 mm</p>

            <b>Pinnaviimistlus:</b>
            <p>vabal valikul</p>

            <b>Helipidavus:</b>
            <p>Rw 41-53 dB</p>
        </div>
      </div>

  </div>

<div className="ta-img">
    <img src={hhh} alt="Tabel" className="responsive-img"></img>
</div>


  </Layout>
)

export default tehnilisedAndmed